import {
  BarElement,
  CategoryScale,
  Chart as ChartJS,
  Legend,
  LinearScale,
  LineElement,
  PointElement,
  Title,
  Tooltip,
} from "chart.js";
import GpxParser from "gpxparser";
import moment from "moment";
import { useEffect, useState } from "react";
import { Line } from "react-chartjs-2";
import { useParams } from "react-router-dom";
import { toast } from "react-toastify";
import { getRideById } from "../../api/ridesService";
import { roundNumber } from "../../utils/utils";
import MapComponent from "./mapComponent/mapComponent";
import "./ride.scss";
import RideDetailCard from "./rideDetailCard/rideDetailCard";

ChartJS.register(
  CategoryScale,
  LinearScale,
  BarElement,
  PointElement,
  LineElement,
  Title,
  Tooltip,
  Legend
);

const chartElevationKilometersOptions = {
  responsive: true,
  plugins: {
    legend: {
      display: true,
      position: "top",
    },
    title: {
      display: true,
      text: "Altitude en mètre suivant les kilomètres",
    },
  },
};

const chartKilometersTimeOptions = {
  responsive: true,
  plugins: {
    legend: {
      display: false,
      position: "top",
    },
    title: {
      display: true,
      text: "Kilomètres parcourus en fonction du temps",
    },
  },
};

export default function Ride() {
  const [ride, setRide] = useState({});
  const { id } = useParams();
  const [positions, setPositions] = useState();
  const [center, setCenter] = useState();
  const [distance, setDistance] = useState();
  const [elevation, setElevation] = useState([]);
  const [avgElevation, setAvgElevation] = useState([]);
  const [duration, setDuration] = useState();
  const [maxElevation, setMaxElevation] = useState();
  const [minElevation, setMinElevation] = useState();

  const chartElevationKilometersData = {
    labels: distance,
    datasets: [
      {
        label: "Altitude",
        data: elevation,
        fill: false,
        backgroundColor: "rgba(75,192,192,0.2)",
        borderColor: "black",
        pointRadius: 0,
        pointHitRadius: 40,
      },
      {
        label: "Altitude moyenne",
        data: Array(elevation.length).fill(avgElevation),
        borderColor: "rgb(156,189,202)",
        pointRadius: 0,
        pointHitRadius: 40,
      },
    ],
  };

  const chartKilometersTimeData = {
    labels: duration,
    datasets: [
      {
        label: "Altitude",
        data: distance,
        fill: true,
        backgroundColor: "rgba(75,192,192,0.2)",
        borderColor: "rgba(75,192,192,1)",
        pointRadius: 0,
        pointHitRadius: 40,
      },
    ],
  };

  useEffect(() => {
    getRideById(id)
      .then((ride) => {
        setRide(ride);

        const gpx = new GpxParser();
        try {
          gpx.parse(ride.trace);
          setDuration(
            gpx.tracks[0].points.map((point) => {
              const [, ...rest] = JSON.stringify(point.time).split("T");
              const hour = rest[0].split(".");
              return hour[0];
            })
          );
          setElevation(gpx.tracks[0].points.map((point) => point.ele));
          setAvgElevation(roundNumber(gpx.tracks[0].elevation.avg));
          setMaxElevation(roundNumber(gpx.tracks[0].elevation.max));
          setMinElevation(roundNumber(gpx.tracks[0].elevation.min));
          setDistance(
            gpx.tracks[0].distance.cumul.map((distance) =>
              roundNumber(distance * 0.001)
            )
          );
          setCenter([gpx.tracks[0].points[0].lat, gpx.tracks[0].points[0].lon]);
          setPositions(
            gpx.tracks[0].points.map((point) => [point.lat, point.lon])
          );
        } catch (error) {
          toast.error("erreur lors du parsing du fichier gpx");
        }
      })
      .catch((err) => {
        toast.error(err.message);
      });
  }, [id]);

  return (
    <div className="rideContainer">
      <div className="info">
        <div className="title">
          <div>
            {ride.label} <span> — {ride.sport}</span>
          </div>
          {moment(ride.datejour).format("Do MMM YYYY")}
        </div>
        <div className="rideContainer">
          <RideDetailCard value={ride.distance} unity="km" label="distance" />
          <RideDetailCard value={ride.temps} unity="min" label="Durée" />
          <RideDetailCard value={ride.denivelenegatif} unity="m" label="D -" />
          <RideDetailCard value={ride.denivelepositif} unity="m" label="D +" />
          <RideDetailCard value={maxElevation} unity="m" label="D max" />
          <RideDetailCard value={minElevation} unity="m" label="D min" />
          <RideDetailCard value={avgElevation} unity="m" label="D ~" />
        </div>
        <Line
          options={chartElevationKilometersOptions}
          data={chartElevationKilometersData}
        />
        <Line
          options={chartKilometersTimeOptions}
          data={chartKilometersTimeData}
        />
      </div>
      <div className="mapContainer">
        {positions && <MapComponent positions={positions} center={center} />}
      </div>
    </div>
  );
}
