import { Table, Tag } from "antd";
import moment from "moment";
import { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { toast } from "react-toastify";
import { getAllRides } from "../../api/ridesService";
import {
  getColorTagOfShoes,
  getColorTagOfSport,
  renderTime,
} from "../../utils/utils";
import SkeletonTable from "../shared/skeletonTable/skeletonTable";
import "./rides.scss";

const columns = [
  {
    title: "Entrainement",
    dataIndex: "label",
    key: "label",
    render: (text) => <strong onClick={() => console.log(text)}>{text}</strong>,
    sorter: (a, b) => a.label.localeCompare(b.label),
    ellipsis: true,
    filters: [
      {
        text: "Semi-marathon",
        value: "semi-marathon",
      },
      {
        text: "Aix-en-Provence",
        value: "aix",
        children: [
          {
            text: "Sainte Victoire",
            value: "sainte-victoire",
          },
        ],
      },
    ],
    filterMode: "tree",
    onFilter: (value, data) => data.label.toLowerCase().startsWith(value),
  },
  {
    title: "Distance",
    dataIndex: "distance",
    key: "distance",
    render: (text) => <>{text} km</>,
    sorter: (a, b) => Number(a.distance) - Number(b.distance),
    ellipsis: true,
    filters: [
      {
        text: "< 10 km",
        value: "lessThan10",
      },
      {
        text: "10-20 km",
        value: "between10and20",
      },
      {
        text: "20-50 km",
        value: "between20and50",
      },
      {
        text: "> 50 km",
        value: "moreThan50",
      },
    ],
    filterMode: "menu",
    onFilter: (value, data) => {
      switch (value) {
        case "lessThan10":
          return data.distance <= 10;
        case "between10and20":
          return data.distance > 10 && data.distance <= 20;
        case "between20and50":
          return data.distance > 20 && data.distance <= 50;
        case "moreThan50":
          return data.distance > 50;
        default:
          break;
      }
    },
  },
  {
    title: "Temps",
    dataIndex: "temps",
    key: "temps",
    render: (_, { temps }) => <>{renderTime(temps)}</>,
    sorter: (a, b) => Number(a.temps) - Number(b.temps),
    ellipsis: true,
  },
  {
    title: "Date",
    dataIndex: "datejour",
    key: "datejour",
    render: (_, { datejour }) => <>{moment(datejour).format("Do MMM YYYY")}</>,
    sorter: (a, b) => moment(a.datejour) - moment(b.datejour),
    ellipsis: true,
  },
  {
    title: "Chaussures",
    dataIndex: "shoes",
    key: "shoes",
    render: (_, { shoes }) => (
      <Tag color={getColorTagOfShoes(shoes)} key={shoes}>
        {shoes?.toUpperCase()}
      </Tag>
    ),
    ellipsis: true,
  },
  {
    title: "Sport",
    key: "sport",
    dataIndex: "sport",
    render: (_, { sport }) => (
      <Tag color={getColorTagOfSport(sport)} key={sport}>
        {sport.toUpperCase()}
      </Tag>
    ),
    ellipsis: true,
  },
];

export default function Rides() {
  const [rides, setRides] = useState([]);
  // const [popup, setPopup] = useState({ ride: {}, visible: false, x: 0, y: 0 });
  const [isLoading, setIsLoading] = useState(false);
  const navigate = useNavigate();

  useEffect(() => {
    setIsLoading(true);
    getAllRides()
      .then((rides) => {
        setRides(rides);
      })
      .catch((err) => {
        toast.error(err);
      })
      .finally(() => setIsLoading(false));
  }, []);

  // TODO add context menu to table row
  // const handleClickOnContextMenu = (data, event) => {
  //   //disable default context menu
  //   event.preventDefault();
  //   document.addEventListener(`click`, function onClickOutside() {
  //     setPopup((prevState) => (prevState.visible = false));
  //     document.removeEventListener(`click`, onClickOutside);
  //   });
  //   setPopup({
  //     ride: data,
  //     visible: true,
  //     x: event.pageX,
  //     y: event.pageY,
  //   });
  // };

  const handleRideDetail = (data) => {
    console.log(data);
    navigate(`/ride/${data.id}`);
  };

  return (
    <div className="ridesContainer">
      <p>Entrainements</p>
      {isLoading ? (
        <SkeletonTable />
      ) : (
        <Table
          rowKey="uid"
          columns={columns}
          dataSource={rides}
          onRow={(data) => ({
            // onClick: () => console.log("###############", data),
            onDoubleClick: () => handleRideDetail(data),
            // onContextMenu: (event) => {
            //   handleClickOnContextMenu(data, event);
            // },
          })}
          rowClassName="table"
        />
      )}
      {/* <Popup data={popup} /> */}
    </div>
  );
}
