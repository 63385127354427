import { Route, Routes } from "react-router-dom";
import { ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import LoggedIn from "../components/loggedIn/loggedIn";
import Main from "../components/main/main";
import PrivateRoute from "./privateRoute";

export default function Router() {
  return (
    <>
      <ToastContainer position="bottom-center" autoClose={3000} theme="dark" />
      <Routes>
        {/* <Redirect from='*' to='/404' /> */}
        <Route path="/login" element={<LoggedIn />} />
        {/* <Route
          path="/dashboard"
          element={<PrivateRoute Component={Dashboard} />}
        />
        <Route path="/rides" element={<PrivateRoute Component={Rides} />} /> */}
        <Route path="/*" element={<PrivateRoute Component={Main} />} />
      </Routes>
    </>
  );
}
