import {
  CalendarOutlined,
  CloudDownloadOutlined,
  LayoutOutlined,
  MessageOutlined,
  ProfileOutlined,
  ProjectOutlined,
  StockOutlined,
} from '@ant-design/icons';
import { useEffect, useState } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import { toast } from 'react-toastify';
import {
  CALENDAR,
  DASHBOARD,
  DOWNLOAD,
  MESSAGES,
  RIDES,
  STATISTICS,
} from '../../models/enum/enum';
import './sidePanel.scss';

export default function SidePanel() {
  const [selectedElement, setSelectedElement] = useState<string>('');
  const navigate = useNavigate();
  const location = useLocation();

  // use to keep the visual information of selected category when page is refresh
  useEffect(() => {
    const finalUri = location.pathname.split('/').pop();
    setSelectedElement(finalUri ? finalUri : '');
  }, [location]);

  const handleSelectedElement = (selectedElement: string) => {
    setSelectedElement(selectedElement);
    switch (selectedElement) {
      case DASHBOARD:
        navigate('/dashboard');
        break;
      case RIDES:
        navigate('/rides');
        break;
      case MESSAGES:
        toast.info('Messages coming soon');
        break;
      case CALENDAR:
        toast.info('Calendar coming soon');
        break;
      case STATISTICS:
        toast.info('Statistics coming soon');
        break;
      case DOWNLOAD:
        navigate('/download');
        break;
      default:
        break;
    }
  };

  /**
   * if selected categorie match the categorie we display an visual information
   * @param value selected categorie
   * @returns a css class
   */
  const isSelected = (value: string) => {
    if (value === selectedElement) {
      return 'selectedElement';
    } else return '';
  };

  return (
    <div className="sidePanel">
      <LayoutOutlined className="icons" />
      <ProjectOutlined
        className={`icons ${isSelected(DASHBOARD)}`}
        onClick={() => handleSelectedElement(DASHBOARD)}
      />
      <ProfileOutlined
        className={`icons ${isSelected(RIDES)}`}
        onClick={() => handleSelectedElement(RIDES)}
      />
      <MessageOutlined
        className={`icons ${isSelected(MESSAGES)}`}
        onClick={() => handleSelectedElement(MESSAGES)}
      />
      <CalendarOutlined
        className={`icons ${isSelected(CALENDAR)}`}
        onClick={() => handleSelectedElement(CALENDAR)}
      />
      <StockOutlined
        className={`icons ${isSelected(STATISTICS)}`}
        onClick={() => handleSelectedElement(STATISTICS)}
      />
      <CloudDownloadOutlined
        className={`icons ${isSelected(DOWNLOAD)}`}
        onClick={() => handleSelectedElement(DOWNLOAD)}
      />
    </div>
  );
}
