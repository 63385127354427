import { ROUND_DECIMAL_KILOMETERS } from '../models/enum/enum';

const roundNumber = (number, fixed = ROUND_DECIMAL_KILOMETERS) => {
  return Number(number).toFixed(fixed);
};

const convertMinutesintoHours = (number) => {
  let minutes = number % 60;
  const hours = parseInt(number / 60, 0);
  minutes = minutes < 10 ? `0${minutes}` : minutes;
  return { hours, minutes };
};

/**
 * convert a time from m to h:m
 * @param {String} time duration of a ride in minute
 * @returns {String} `${hours}h${minutes}m`
 */
const renderTime = (time) => {
  const a = convertMinutesintoHours(time);
  return `${a.hours}h${a.minutes}m`;
};

const getColorTagOfShoes = (shoes) => {
  switch (shoes?.toUpperCase()) {
    case 'HOKA ONE ONE SPEED MAFATE 3':
      return 'black';
    case 'ASICS':
      return 'rgb(163, 197, 212)';
    case 'CHAUSSURES DE VTT':
      return '#38D8C0';
    case 'WILDHORSE 6':
      return '#6DC1D8';
    default:
      break;
  }
};

const getColorTagOfSport = (sport) => {
  switch (sport.toUpperCase()) {
    case 'COURSE À PIEDS':
      return '#D0D4EA';
    case 'TRAIL':
      return '#EAD0D4';
    case 'VÉLO':
      return '#E6D0EA';
    default:
      break;
  }
};

export {
  convertMinutesintoHours,
  getColorTagOfShoes,
  getColorTagOfSport,
  renderTime,
  roundNumber,
};
