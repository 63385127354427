import { useEffect } from 'react';
import { Navigate, Route, Routes } from 'react-router-dom';
import { useQueryParams } from '../../hooks/useQueryParams';
import PrivateRoute from '../../routes/privateRoute';
import { saveUserStravaInfo } from '../../services/userService';
import Dashboard from '../dashboard/dashboard';
import Header from '../header/header';
import ImportExport from '../import-export/import-export';
import Ride from '../ride/ride';
import Rides from '../rides/rides';
import Settings from '../settings/settings';
import SidePanel from '../sidePanel/sidePanel';
import './main.scss';

export default function Main() {
  return (
    <div className="main">
      <SidePanel />
      <div className="container">
        <Header />
        <div className="content">
          <Routes>
            <Route
              path="/dashboard"
              element={<PrivateRoute Component={Dashboard} />}
            />
            <Route
              path="/ride/:id"
              element={<PrivateRoute Component={Ride} />}
            />
            <Route path="/rides" element={<PrivateRoute Component={Rides} />} />
            <Route
              path="/download"
              element={<PrivateRoute Component={ImportExport} />}
            />
            <Route
              path="/settings"
              element={<PrivateRoute Component={Settings} />}
            />
            <Route
              path=""
              element={<PrivateRoute Component={StravaRedirect} />}
            />
            <Route path="*" element={<Navigate to="/dashboard" />} />
          </Routes>
        </div>
      </div>
    </div>
  );
}

function StravaRedirect() {
  const queryMap = useQueryParams();
  useEffect(() => {
    console.log('a :>> ', queryMap);
    const body = Object.fromEntries(queryMap);
    console.log(body);
    if (Object.keys(body).length) {
      saveUserStravaInfo(body);
    }

    // window.location.replace(`${process.env.REACT_APP_REDIRECT_URL_STRAVA}`);
  }, [queryMap]);

  return <></>;
}
