import client from "./axios";

/**
 * get all rides of the user
 * @returns {Promise}
 */
const getAllRides = () => {
  return new Promise((resolve, reject) => {
    client
      .get(`/rides`)
      .then((response) => {
        resolve(response.data);
      })
      .catch((error) => {
        if (error.response) {
          reject(error.response.data);
        } else if (error.request) {
          reject({ message: "Impossible de joindre le serveur" });
        } else {
          reject({ message: "Erreur inconnue" });
        }
      });
  });
};

/**
 * get a ride by id
 * @param {number} id - id of the ride
 * @returns {Promise}
 */
const getRideById = (id) => {
  return new Promise((resolve, reject) => {
    client
      .get(`/ride/${id}`)
      .then((response) => {
        resolve(response.data);
      })
      .catch((error) => {
        if (error.response) {
          reject(error.response.data);
        } else if (error.request) {
          reject({ message: "Impossible de joindre le serveur" });
        } else {
          reject({ message: "Erreur inconnue" });
        }
      });
  });
};

/**
 * add a new ride for an user
 * @param body - required
 * @returns {Promise}
 */
const addNewTrainingForAnUser = (body) => {
  return new Promise((resolve, reject) => {
    client
      .post(`/ride`, body)
      .then((response) => {
        resolve(response.data);
      })
      .catch((error) => {
        if (error.response) {
          reject(error.response.data);
        } else if (error.request) {
          reject({ message: "Impossible de joindre le serveur" });
        } else {
          reject({ message: "Erreur inconnue" });
        }
      });
  });
};

export { getAllRides, addNewTrainingForAnUser, getRideById };
