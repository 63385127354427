import { STRAVA_URL_CONNECTION } from '../../models/enum/enum';
import './settings.scss';

export default function Settings() {
  return (
    <div className="">
      <a href={STRAVA_URL_CONNECTION}>se connecter à strava</a>
    </div>
  );
}
