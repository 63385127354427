import client from "./axios";

/**
 * get distances statistics and difference with past section
 * @returns {Promise}
 */
const getDistances = (interval) => {
  return new Promise((resolve, reject) => {
    client
      .get(`/distance?interval=${interval}`)
      .then((response) => {
        resolve(response.data);
      })
      .catch((error) => {
        if (error.response) {
          reject(error.response.data);
        } else if (error.request) {
          reject({ message: "Impossible de joindre le serveur" });
        } else {
          reject({ message: "Erreur inconnue" });
        }
      });
  });
};

/**
 * get elevation statistics and difference with past section
 * @returns {Promise}
 */
const getElevation = (interval) => {
  return new Promise((resolve, reject) => {
    client
      .get(`/elevation?interval=${interval}`)
      .then((response) => {
        resolve(response.data);
      })
      .catch((error) => {
        if (error.response) {
          reject(error.response.data);
        } else if (error.request) {
          reject({ message: "Impossible de joindre le serveur" });
        } else {
          reject({ message: "Erreur inconnue" });
        }
      });
  });
};

export { getDistances, getElevation };
