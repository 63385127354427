import {
  CalendarOutlined,
  LogoutOutlined,
  MessageOutlined,
  SettingOutlined,
  UserOutlined,
} from "@ant-design/icons";
import { Avatar, Badge, Dropdown } from "antd";
import { useNavigate } from "react-router-dom";
import { clearLocalStorage } from "../../utils/localStorage";
import "./header.scss";

export default function Header() {
  const navigate = useNavigate();
  const items = [
    {
      key: "settings",
      route: "/settings",
      label: (
        <>
          <SettingOutlined />
          Paramètres
        </>
      ),
    },
    {
      key: "logout",
      route: "/login",
      label: (
        <>
          <LogoutOutlined />
          Déconnexion
        </>
      ),
    },
  ];

  const onClick = ({ key }) => {
    const itemsClicked = items.find((el) => el?.key === key);

    if (itemsClicked.key === "logout") clearLocalStorage();

    navigate(itemsClicked.route);
  };

  return (
    <div className="header">
      <span>Bonjour Maxence</span>
      <div className="about">
        <MessageOutlined className="icons" />
        <CalendarOutlined className="icons" />
        <Dropdown
          menu={{
            items,
            onClick,
          }}
          placement="bottomRight"
        >
          <Badge count={1} onClick={(e) => e.preventDefault()}>
            <Avatar size={40} icon={<UserOutlined />} />
          </Badge>
        </Dropdown>

        {/* <DownOutlined className="icons" /> */}
      </div>
    </div>
  );
}
