import { LockOutlined, UserOutlined } from '@ant-design/icons';
import { Button, Input, Spin } from 'antd';
import CryptoJS from 'crypto-js';
import { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { toast } from 'react-toastify';
import { login } from '../../api/usersService';
import { ENCRYPT_KEY, USER_TOKEN } from '../../models/enum/enum';
import { setLocalStorageItem } from '../../utils/localStorage';
import './loggedIn.scss';

export default function LoggedIn() {
  const [pseudo, setPseudo] = useState('');
  const [password, setPassword] = useState('');
  const [isLoading, setIsLoading] = useState(false);

  const navigate = useNavigate();

  const handleLoggedIn = () => {
    setIsLoading(true);

    // Encrypt password
    var encryptPassword = CryptoJS.AES.encrypt(
      password,
      ENCRYPT_KEY
    ).toString();

    login({ username: pseudo, password: encryptPassword })
      .then((response) => {
        setLocalStorageItem(USER_TOKEN, response.token);
        navigate('/dashboard');
        toast('Bonjour');
      })
      .catch((error) => toast.error(error.message))
      .finally(() => setIsLoading(false));
  };

  return (
    <div className="loggedInContainer">
      <div className="input">
        <UserOutlined />
        <Input
          bordered={false}
          placeholder="Identifiant"
          value={pseudo}
          onChange={(e) => setPseudo(e.target.value)}
        />
      </div>
      <div className="input">
        <LockOutlined />
        <Input.Password
          bordered={false}
          placeholder="Mot de passe"
          value={password}
          onChange={(e) => setPassword(e.target.value)}
        />
      </div>
      <Button className="signIn" type="primary" onClick={handleLoggedIn}>
        {isLoading ? <Spin /> : 'Se connecter'}
      </Button>
      Créer un compte
    </div>
  );
}
