import { Skeleton, Table } from "antd";

const columns = [
  {
    title: "Entrainement",
    dataIndex: "label",
    key: "label",
    render: () => <Skeleton.Input active size="small" />,
    ellipsis: true,
  },
  {
    title: "Distance",
    dataIndex: "distance",
    key: "distance",
    render: () => <Skeleton.Input active size="small" />,
    ellipsis: true,
  },
  {
    title: "Temps",
    dataIndex: "temps",
    key: "temps",
    render: () => <Skeleton.Input active size="small" />,
    ellipsis: true,
  },
  {
    title: "Date",
    dataIndex: "datejour",
    key: "datejour",
    render: () => <Skeleton.Input active size="small" />,
    ellipsis: true,
  },
  {
    title: "Chaussures",
    dataIndex: "shoes",
    key: "shoes",
    render: () => <Skeleton.Input active size="small" />,
    ellipsis: true,
  },
  {
    title: "Sport",
    key: "sport",
    dataIndex: "sport",
    render: () => <Skeleton.Input active size="small" />,
    ellipsis: true,
  },
];
export default function SkeletonTable() {
  return (
    <Table
      rowKey="uid"
      columns={columns}
      dataSource={Array(10).fill(null)}
      pagination={false}
    />
  );
}
