import { DownOutlined } from "@ant-design/icons";
import { Button, Dropdown } from "antd";
import { useEffect, useState } from "react";
import { RiPinDistanceFill } from "react-icons/ri";
import { toast } from "react-toastify";
import { getDistances } from "../../../api/statistics";
import { roundNumber } from "../../../utils/utils";
import "./distanceCard.scss";

const items = [
  {
    key: "0",
    label: <>semaine</>,
    interval: "week",
  },
  {
    key: "1",
    label: <>mois</>,
    interval: "month",
  },
];

export default function DistanceCard() {
  const [itemsSelected, setItemSelected] = useState(items[0]);
  const [distance, setDistance] = useState({});
  const [isLoading, setIsLoading] = useState(false);

  useEffect(() => {
    setIsLoading(true);
    getDistances(itemsSelected.interval)
      .then((result) => {
        setDistance(result);
      })
      .catch((err) => {
        toast.error(err.message);
      })
      .finally(() => setIsLoading(false));
  }, [itemsSelected]);

  const onClick = ({ key }) => {
    const itemsClicked = items.find((el) => el.key === key);
    setItemSelected(itemsClicked);
  };

  return (
    <div className="card">
      {!isLoading && (
        <>
          <RiPinDistanceFill className="icon" />
          <Dropdown
            menu={{
              items,
              selectable: true,
              defaultSelectedKeys: ["0"],
              onClick,
            }}
            className="dropdown"
          >
            <Button onClick={(e) => e.preventDefault()}>
              {itemsSelected.label}
              <DownOutlined />
            </Button>
          </Dropdown>
          <div className="km">
            {roundNumber(distance.currentDistance, 0)} km
          </div>
          <div className="ratio">
            <span
              className={`${distance.ratio >= 0 ? "positive" : "negative"}`}
            >
              {distance.ratio >= 0 && "+ "}
              {roundNumber(distance.ratio, 0)} %
            </span>
          </div>
        </>
      )}
    </div>
  );
}
