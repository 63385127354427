import "leaflet/dist/leaflet.css";
import {
  LayersControl,
  MapContainer,
  Polyline,
  TileLayer,
} from "react-leaflet";

export default function MapComponent(props) {
  const { positions, center } = props;

  return (
    <MapContainer
      style={{
        width: "100%",
        height: "100%",
      }}
      center={center}
      zoom={13}
      scrollWheelZoom={true}
    >
      <TileLayer
        attribution='&copy; <a href="https://www.openstreetmap.org/copyright">OpenStreetMap</a> contributors'
        url="https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png"
      />
      <LayersControl position="topright">
        <LayersControl.BaseLayer checked name="OpenStreetMap">
          <TileLayer
            // attribution='&copy; <a href=https://www.openstreetmap.org/copyright>OpenStreetMap</a> contributors'
            url="https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png"
          />
        </LayersControl.BaseLayer>
        <LayersControl.BaseLayer name="OpenTopoMap">
          <TileLayer
            // attribution='&copy; <a href=https://www.openstreetmap.org/copyright>OpenStreetMap</a> contributors'
            url="https://{s}.tile.opentopomap.org/{z}/{x}/{y}.png"
          />
        </LayersControl.BaseLayer>
        {/* <LayersControl.BaseLayer name="NASA">
          <TileLayer
            url="https://gibs-{s}.earthdata.nasa.gov/wmts/epsg3857/best/BlueMarble_ShadedRelief_Bathymetry/default//EPSG3857_500m/{z}/{y}/{x}.jpeg"
            //attribution="&copy; NASA Blue Marble, image service by OpenGeo"
          />
        </LayersControl.BaseLayer> */}
        <LayersControl.BaseLayer name="thunderforest">
          <TileLayer
            //attribution="&copy; <a href=http://osm.org/copyright>OpenStreetMap</a> contributors"
            url="https://tiles.stadiamaps.com/tiles/alidade_smooth_dark/{z}/{x}/{y}{r}.png"
          />
        </LayersControl.BaseLayer>
      </LayersControl>

      <Polyline pathOptions={{ color: "black" }} positions={positions} />
    </MapContainer>
  );
}
