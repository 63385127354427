/* This is used to determine if a user is authenticated and
 * if they are allowed to visit the page they navigated to.
 *
 * If they are: they proceed to the page
 * If not: they are redirected to the login page.
 */
import { Navigate } from 'react-router-dom';
import { USER_TOKEN } from '../models/enum/enum';
import { getLocalStorageItem } from '../utils/localStorage';

const PrivateRoute = ({
  Component,
}: {
  Component: React.FunctionComponent<any>;
}) => {
  const auth = getLocalStorageItem(USER_TOKEN);

  return auth ? <Component /> : <Navigate to="/login" />;
};

export default PrivateRoute;
