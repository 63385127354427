//local storage
const USER_TOKEN = "token";

//
const ENCRYPT_KEY = "kNJ3*!Xrvd8*KqS>";

// categories
const DASHBOARD = "dashboard";
const RIDES = "rides";
const MESSAGES = "messages";
const CALENDAR = "calendar";
const STATISTICS = "statistics";
const DOWNLOAD = "download";

// STRAVA
const STRAVA_URL_CONNECTION = `https://www.strava.com/oauth/authorize?client_id=109312&response_type=code&redirect_uri=${process.env.REACT_APP_REDIRECT_URL_STRAVA}&approval_prompt=force&scope=activity:read_all`;

//
const ROUND_DECIMAL_KILOMETERS = 2;

//
export enum HTTP_METHOD {
  GET = "get",
  POST = "post",
  PUT = "put",
  DELETE = "delete",
  PATCH = "patch",
}

export {
  CALENDAR,
  DASHBOARD,
  DOWNLOAD,
  ENCRYPT_KEY,
  MESSAGES,
  RIDES,
  ROUND_DECIMAL_KILOMETERS,
  STATISTICS,
  STRAVA_URL_CONNECTION,
  USER_TOKEN,
};
