import "./rideDetailCard.scss";

export default function RideDetailCard(props) {
  const { value, label, unity } = props;

  return (
    <div className="RideDetail">
      {value} {unity}
      <br />
      <span> {label}</span>
    </div>
  );
}
