import { LoadingOutlined, UploadOutlined } from "@ant-design/icons";
import {
  Button,
  DatePicker,
  Form,
  Input,
  InputNumber,
  Select,
  Steps,
  Upload,
} from "antd";
import moment from "moment";
import { useEffect, useState } from "react";
import { toast } from "react-toastify";
import { addNewTrainingForAnUser } from "../../../api/ridesService";
import { getShoes } from "../../../api/shoes";
import { roundNumber } from "../../../utils/utils";
import "./import.scss";

export default function Import() {
  const gpxParser = require("gpxparser");
  const [currentStep, setCurrentStep] = useState(0);
  const [contentFile, setContentFile] = useState("");
  const [form] = Form.useForm();
  const [isNextStepButtonDisabled, setIsNextStepButtonDisabled] =
    useState(true);
  const [isPreviousStepButtonDisabled, setIsPreviousStepButtonDisabled] =
    useState(true);
  const [shoes, setShoes] = useState([]);

  const steps = [
    {
      title: "Importer un fichier",
    },
    {
      title: "Validation",
    },
    {
      title: "enregistrement",
    },
  ];

  useEffect(() => {
    getShoes()
      .then((shoes) => setShoes(shoes))
      .catch((error) => toast.error(error.message));
  }, []);

  /**
   * Read the content of the uploaded file
   * @param {File} file
   * @returns {Promise} always return false to not automatically upload file
   */
  const setContentFiles = (file) => {
    return new Promise((_, reject) => {
      const reader = new FileReader();
      reader.readAsText(file);
      reader.onload = (e) => {
        var gpx = new gpxParser();
        gpx.parse(e.target.result);

        // edit properties of gpx file
        gpx.metadata.author.name = "Maxence BRINGUIER";
        gpx.metadata.author.watch = "Polar Vantage M2";

        // calculate the duration of the training
        const start = moment(gpx.tracks[0].points[0].time);
        const end = moment(
          gpx.tracks[0].points[gpx.tracks[0].points.length - 1].time
        );
        const duration = moment.duration(end.diff(start));

        form.setFieldsValue({
          // datejour: moment(gpx.metadata.time),
          distance: roundNumber(gpx.tracks[0].distance.total * 0.001),
          denivelepositif: gpx.tracks[0].elevation.neg,
          denivelenegatif: gpx.tracks[0].elevation.pos,
          time: duration._data.hours * 60 + duration._data.minutes,
        });
        setContentFile(e.target.result);
        reject();
      };
    });
  };

  /**
   * use to set state of next button during first step
   */
  useEffect(() => {
    setIsNextStepButtonDisabled(contentFile ? false : true);
  }, [contentFile]);

  /**
   * use to set state of previous button
   */
  useEffect(() => {
    switch (currentStep) {
      case 0:
        setIsPreviousStepButtonDisabled(true);
        break;
      case 1:
      case 2:
        setIsPreviousStepButtonDisabled(false);
        break;
      default:
        break;
    }
  }, [currentStep]);

  const handleOnClickNextStep = () => {
    switch (currentStep) {
      case 0:
        setCurrentStep((prevState) => prevState + 1);
        handleFormChanges();
        break;
      case 1:
        steps[currentStep + 1] = {
          status: "wait",
          icon: <LoadingOutlined />,
        };
        setCurrentStep((prevState) => prevState + 1);
        saveRides();

        break;
      // save new Training
      case 2:
        setCurrentStep((prevState) => prevState + 1);
        break;
      default:
        break;
    }
  };

  const handleOnClickPreviousStep = () => {
    setContentFile("");
    form.resetFields();
    if (currentStep - 1 >= 0) setCurrentStep((prevState) => prevState - 1);
  };

  /**
   * use to set state of next button during second step
   */
  const handleFormChanges = () => {
    const fields = form.getFieldsValue();
    if (
      !fields.datejour ||
      !fields.time ||
      !fields.distance ||
      !fields.label ||
      !fields.sport
    ) {
      setIsNextStepButtonDisabled(true);
    } else {
      setIsNextStepButtonDisabled(false);
    }
  };

  const saveRides = () => {
    const body = {
      ...form.getFieldsValue(),
      datejour: form.getFieldValue("datejour")?.$d,
      trace: contentFile,
    };

    addNewTrainingForAnUser(body)
      .then((data) => {
        toast.success(data.message);
        handleOnClickNextStep();
      })
      .catch((error) => {
        toast.error(error.message);
      });
  };

  return (
    <div className="importContainer">
      <Steps size="small" current={currentStep} items={steps} />
      {currentStep < 2 && (
        <div className="buttonSteps">
          <Button
            type="primary"
            disabled={isPreviousStepButtonDisabled}
            onClick={handleOnClickPreviousStep}
          >
            Etape précédente
          </Button>
          <Button
            type="primary"
            disabled={isNextStepButtonDisabled}
            onClick={handleOnClickNextStep}
          >
            Etape suivante
          </Button>
        </div>
      )}
      {/* step 1 : upload file */}
      {currentStep === 0 && (
        <div className="stepContent">
          <p>Sélectionner un fichier GPX</p>
          <Upload
            accept=".gpx"
            maxCount={1}
            beforeUpload={(file) => {
              return setContentFiles(file);
            }}
            onRemove={() => {
              setContentFile("");
            }}
          >
            <Button icon={<UploadOutlined />} shape="round">
              Click to Upload
            </Button>
          </Upload>
        </div>
      )}
      {/* step 2 : fill the form */}
      {currentStep === 1 && (
        <div className="stepContent">
          <Form
            form={form}
            onFieldsChange={() => handleFormChanges()}
            labelCol={{ span: 10 }}
            wrapperCol={{ span: 100 }}
            layout="horizontal"
          >
            <Form.Item
              label="Nom de la séance"
              name="label"
              rules={[{ required: true }]}
            >
              <Input />
            </Form.Item>
            <Form.Item label="Sport" name="sport" rules={[{ required: true }]}>
              <Select>
                <Select.Option value="Vélo">Vélo</Select.Option>
                <Select.Option value="Trail">Trail</Select.Option>
                <Select.Option value="VélCourse à piedso">
                  Course à pieds
                </Select.Option>
                <Select.Option value="Natation">Natation</Select.Option>
              </Select>
            </Form.Item>
            <Form.Item label="Chaussures" name="shoes">
              <Select>
                {shoes.map((shoe, index) => (
                  <Select.Option key={index} value={shoe.model}>
                    {shoe.model}
                  </Select.Option>
                ))}
              </Select>
            </Form.Item>

            <Form.Item
              label="DatePicker"
              name="datejour"
              rules={[{ required: true }]}
            >
              <DatePicker />
            </Form.Item>
            <Form.Item
              label="Distance (km)"
              name="distance"
              rules={[{ required: true }]}
            >
              <InputNumber />
            </Form.Item>
            <Form.Item
              label="Temps (min)"
              name="time"
              rules={[{ required: true }]}
            >
              <InputNumber />
            </Form.Item>
            <Form.Item label="Dénivelé positif" name="denivelepositif">
              <InputNumber />
            </Form.Item>
            <Form.Item label="Dénivelé négatif" name="denivelenegatif">
              <InputNumber />
            </Form.Item>
          </Form>
        </div>
      )}
      {/* step 2 : training add */}
      {currentStep === 3 && (
        <div className="rideSave"> Entrainement enregistré</div>
      )}
    </div>
  );
}
