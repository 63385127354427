import client from "./axios";

/**
 * get all shoes of an user
 * @returns {Promise}
 */
const getShoes = () => {
  return new Promise((resolve, reject) => {
    client
      .get(`/shoes`)
      .then((response) => {
        resolve(response.data);
      })
      .catch((error) => {
        if (error.response) {
          reject(error.response.data);
        } else if (error.request) {
          reject({ message: "Impossible de joindre le serveur" });
        } else {
          reject({ message: "Erreur inconnue" });
        }
      });
  });
};

export { getShoes };
