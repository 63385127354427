import { useEffect, useMemo, useState } from "react";

export function useQueryParams() {
  const [query, setQuery] = useState<Map<string, string>>(
    new Map<string, string>()
  );
  const url = window.location.search;

  useEffect(() => {
    console.log("url :>> ", url);
    const queries = url.split("?");
    if (queries.length > 1) {
      const queryMap = new Map<string, string>();
      queries[1].split("&").forEach((el) => {
        const query = el.split("=");
        queryMap.set(query[0], query[1]);
      });

      setQuery(queryMap);
    }
  }, [url]);

  return useMemo(() => query, [query]);
}
