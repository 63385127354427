import axios from 'axios';
import { USER_TOKEN } from '../models/enum/enum';
import { getLocalStorageItem } from '../utils/localStorage';

const client = axios.create({
  baseURL: process.env.REACT_APP_API_URL,
  headers: {
    'Content-Type': 'application/json',
    Accept: 'application/json',
    'Access-Control-Allow-Origin': '*',
  },
});

// Request interceptor for API calls
// needed to set authorization header
client.interceptors.request.use(
  async (config) => {
    config.headers = {
      Authorization: `Bearer ${getLocalStorageItem(USER_TOKEN)}`,
    };
    return config;
  },
  (error) => {
    Promise.reject(error);
  }
);

/**
 * shared http request
 * @param {Enumerator} method
 * @param {string} url
 * @param {Object} body
 * @param {Object} params
 * @returns
 */
export const request = (method, url, data, params) => {
  return new Promise((resolve, reject) => {
    client
      .request({
        method,
        url,
        params,
        data,
      })
      .then((response) => {
        resolve(response.data);
      })
      .catch((error) => {
        if (error.response) {
          reject(error.response.data);
        } else if (error.request) {
          reject({ message: 'Impossible de joindre le serveur', error });
        } else {
          reject({ message: 'Erreur inconnue' });
        }
      });
  });
};

export default client;
