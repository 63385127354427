import DistanceCard from "../shared/DistanceCard/distanceCard";
import ElevationCard from "../shared/ElevationCard/elevationCard";
import "./dashboard.scss";
export default function Dashboard() {
  return (
    <div className="dashboardContainer">
      <DistanceCard />
      <ElevationCard />
    </div>
  );
}
