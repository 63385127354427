import { toast } from 'react-toastify';
import { request } from '../api/axios';
import { HTTP_METHOD } from '../models/enum/enum';

/**
 * save authentication user's creditentials for strava
 * @param body
 */
export const saveUserStravaInfo = (body: Object): void => {
  request(HTTP_METHOD.POST, '/stravaInfo', body, {})
    .then((response) => {
      toast.info(response);
    })
    .catch((error) => {
      toast.error(error);
    });
};
