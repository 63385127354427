import { Tabs } from "antd";
import Export from "./export/export";
import "./import-export.scss";
import Import from "./import/import";

export default function ImportExport() {
  const tabs = [
    {
      label: "Import",
      component: <Import />,
    },
    {
      label: "Export",
      component: <Export />,
    },
  ];

  return (
    <div className="ImportExportContainer">
      <Tabs
        defaultActiveKey="1"
        centered
        tabBarGutter={30}
        tabBarStyle={{ fontFamily: "Pacifico", fontSize: "20px" }}
        items={tabs.map((tab, i) => {
          return {
            label: <p>{tab.label}</p>,
            key: i,
            children: tab.component,
          };
        })}
      />
    </div>
  );
}
