import moment from "moment";
import "moment/locale/fr";
import "./App.scss";
import Router from "./routes/router";

export default function App() {
  moment.locale("fr");

  return <Router />;
}
